import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  styles,
  materialTheme,
  CssTextField,
} from "../../common/radiolineStyles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export class MyInventoryDetails extends Component {
  render() {
    const { classes, myInventoryDetails } = this.props;

    const checkIcon = (<CheckIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);
    const closeIcon = (<CloseIcon style={{ marginTop: "-15px", marginBottom: "-7.5px" }} />);

    return (
      <Grid container className={classes.root} spacing={2}>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Typ
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.itemName}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Producent
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.vendor}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Model
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.model != "" ? myInventoryDetails.model : "-"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Extranet ID
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.equipment != null ? myInventoryDetails.equipment : "-"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Nr faktury
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.fvNumber == "" || myInventoryDetails.fvNumber == null
                  ? "-BRAK-"
                  : myInventoryDetails.fvNumber}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Wartość
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.value == "" || myInventoryDetails.value == null
                  ? "-BRAK-"
                  : myInventoryDetails.value}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Środek Trwały
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.isST == true ? checkIcon : myInventoryDetails.isST == false ? closeIcon : "?"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={12} md={12} lg={12}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Informacje dodatkowe
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.description == "" || myInventoryDetails.description == null
                  ? "-BRAK-"
                  : myInventoryDetails.description}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
          <Card className={classes.info} style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Komentarz Pracownika
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.userComment == "" || myInventoryDetails.userComment == null
                  ? "-BRAK-"
                  : myInventoryDetails.userComment}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card className={classes.info} style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Czy posiadany?
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.isOwned == true ? checkIcon : myInventoryDetails.isOwned == false ? closeIcon : "?"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={3} lg={3}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Czy zaakceptowany?
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.accepted == true ? checkIcon : myInventoryDetails.accepted == false ? closeIcon : "?"}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Komentarz Opiekuna
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.managerComment == "" || myInventoryDetails.managerComment == null
                  ? "-BRAK-"
                  : myInventoryDetails.managerComment}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
        <Grid p={0} m={0} item xs={12} sm={6} md={6} lg={6}>
          <Card style={{ margin: 0 }}>
            <CardHeader
              title={
                <Typography className={classes.textName2}>
                  Komentarz Kontrolingu
                </Typography>
              }
            ></CardHeader>
            <CardBody>
              <Typography className={classes.textDescription2}>
                {myInventoryDetails.controlComment == "" || myInventoryDetails.controlComment == null
                  ? "-BRAK-"
                  : myInventoryDetails.controlComment}
              </Typography>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(MyInventoryDetails));