import React, { Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getEventsOwner, getEventTypes, clearEvents, clearEventTypes, addEvent } from "../../Redux/actions/scheduler.js"

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: "10px"
      },
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    },
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  buttonRight: {
    float: "right"
  }
}));

class MainEmployeeView extends React.Component {
  state = {
    dialogOpen: false,
    detailsOpen: false,
    dateMsg: '',
    startDateString: '',
    title: '',
    startDate: null,
    endDate: null,
    allDay: false,
    eventType: 2,
    eventPK: null,
    opiekaType: 1,
    backgroundColor: '#000000',
    borderColor: '#000000',
    textColor: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    events: PropTypes.any.isRequired,
    eventTypes: PropTypes.any.isRequired,
    getEventsOwner: PropTypes.func.isRequired,
    getEventTypes: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    clearEvents: PropTypes.func.isRequired,
    clearEventTypes: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.getEventsOwner(this.props.auth.user.id);
    this.props.getEventTypes();
  }

  componentWillUnmount() {
    this.props.clearEvents();
    this.props.clearEventTypes();
  }


  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect()

    this.setState({
      dialogOpen: true,
      startDate: selectInfo.start,
      endDate: selectInfo.end,
      allDay: selectInfo.allDay,
    })
  }

  handleSaveEvent = () => {
    var backgroundColor = this.state.backgroundColor;
    var borderColor = this.state.borderColor;
    var textColor = this.state.textColor;
    var types = this.props.eventTypes;
    var title = this.state.title;
    var id = this.state.eventType;
    var opiekaType = this.state.opiekaType;

    //do przyszłej zamainy na coś lepszego?
    for (var i = 0; i < types.length; i++) {
      if (id == types[i].pk) {
        if (types[i].backgroundColor != null) backgroundColor = types[i].backgroundColor;
        if (types[i].borderColor != null) borderColor = types[i].borderColor;
        if (types[i].textColor != null) textColor = types[i].textColor;
      }
    }

    switch (id) {
      case 1:
        title = "Urlop";
        break;
      case 2:
        title = "Praca Zdalna";
        break;
      case 3:
        title = "L4";
        break;
      case 4:
        title = "Nieobecność";
        break;
      case 5:
        title = "Delegacja";
        break;
      case 7:
        title = "Planowany Urlop";
        break;
      default:
        break;
    }

    if (id == 6) {
      if (opiekaType == 1) title = "Opieka - Urlop";
      if (opiekaType == 2) title = "Opieka - L4";
    }

    const newEvent = {
      title,
      groupId: id,
      start: this.state.startDate,
      end: this.state.endDate,
      allDay: this.state.allDay,
      backgroundColor,
      borderColor,
      textColor,
      editable: false,
      type: this.state.eventType,
      resourceId: this.props.auth.user.id,
    }

    this.props.addEvent(newEvent);

    this.setState({
      dialogOpen: false,
      title: '',
      startDate: null,
      endDate: null,
      allDay: false,
      eventType: 2,
      backgroundColor: '#000000',
      borderColor: '#000000',
      textColor: null,
      opiekaType: 1,
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      title: '',
      startDate: null,
      endDate: null,
      allDay: false,
      eventType: 2,
      backgroundColor: '#000000',
      borderColor: '#000000',
      textColor: null,
      opiekaType: 1,
    })
  }

  handleDetailsOpen = (eventObj) => {
    var startD = new Date(eventObj.start);
    var endD = new Date(eventObj.end);
    var roznica = endD - startD;
    var startDateString = startD.getDate() + '.' + (startD.getMonth() + 1) + '.' + startD.getFullYear();
    var dateMsg = "";

    if (roznica % 86400000 == 0) {
      var days = roznica / 86400000;
      if (days == 1) {
        dateMsg = '1 dzień';
      }
      else {
        dateMsg = days + ' dni';
      }
    }
    else {
      dateMsg = startD.toTimeString().split(' ')[0] + ' - ' + endD.toTimeString().split(' ')[0];
    }

    this.setState({
      detailsOpen: true,
      title: eventObj.title,
      startDateString,
      dateMsg,
      eventPK: eventObj.extendedProps.pk,
    })
  }

  handleDetailsClose = () => {
    this.setState({
      detailsOpen: false,
      title: '',
      startDateString: '',
      dateMsg: '',
      eventPK: null,
    })
  }

  handleEventClick = (clickInfo) => {
    var eventObj = clickInfo.event;
    clickInfo.jsEvent.preventDefault();
    this.handleDetailsOpen(eventObj);

    /*
    if (eventObj.url && eventObj.url != ' ') {
      //Zdarzenie z linkiem
      alert(
        'Clicked ' + eventObj.extendedProps + '.\n' +
        'Will open ' + eventObj.url + ' in a new tab'
      );
      window.open(eventObj.url);
    } else {
      //Zdarzenie bez linku
    }
    */
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  render() {
    const {
      dialogOpen,
      detailsOpen,
      title,
      dateMsg,
      eventType,
      opiekaType,
      startDateString,
    } = this.state;
    const classes = useStyles;

    const titleField = (
      <TextField
        margin="dense"
        id="name"
        label="Nazwa wydarzenia"
        type="text"
        fullWidth
        variant="standard"
        value={title}
        onChange={this.handleTextList("title")}
      />
    );

    const opiekaField = (
      <FormControl required className={classes.formControl} fullWidth>
        <InputLabel htmlFor="opiekaType">
          Rodzaj
        </InputLabel>
        <Select
          name="opiekaType"
          className={classes.selectEmpty}
          value={opiekaType}
          onChange={this.handleTextList("opiekaType")}
        >
          <MenuItem key='1' value='1'>
            <ListItemText primary='Opieka - Urlop' />
          </MenuItem>
          <MenuItem key='2' value='2'>
            <ListItemText primary='Opieka - L4' />
          </MenuItem>
        </Select>
      </FormControl>
    );

    return (
      <Fragment>
        <ThemeProvider theme={materialTheme}>
          <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
            <DialogTitle>Dodaj</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Dodaj nowe wydarzenie (urlop / praca zdalna / spotkanie)
              </DialogContentText>
              <FormControl required className={classes.formControl} fullWidth>
                <InputLabel htmlFor="eventType">
                  Typ
                </InputLabel>
                <Select
                  name="eventType"
                  className={classes.selectEmpty}
                  value={eventType}
                  onChange={this.handleTextList("eventType")}
                >
                  {this.props.eventTypes.map((item) => {
                    return (
                      <MenuItem key={item.pk} value={item.pk}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {eventType > 6 && eventType != 7 ? titleField : ""}
              {eventType == 6 ? opiekaField : ""}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose}>Anuluj</Button>
              <Button onClick={this.handleSaveEvent}>Gotowe</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <ThemeProvider theme={materialTheme}>
          <Dialog open={detailsOpen} onClose={this.handleDetailsClose}>
            <DialogTitle>Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Informacje dotyczące wydarzenia:
              </DialogContentText>
              <DialogContentText>
                Typ: {title}
              </DialogContentText>
              <DialogContentText>
                Rozpoczęcie: {startDateString}
              </DialogContentText>
              <DialogContentText>
                Okres trwania: {dateMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDetailsClose}>Zamknij</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <Grid container justify="flex-start" style={{ width: "100%", padding: "1% 10%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyItems: "center" }}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              locale='plLocale'
              firstDay='1'
              headerToolbar={{
                right: 'prev,next today',
                center: 'title',
                left: 'dayGridMonth,dayGridWeek,timeGridDay',
              }}
              aspectRatio='2'
              dayMaxEventRows='true'
              events={this.props.events}
              views={{
                dayGridMonth: {
                  dayMaxEventRows: 4
                },
                timeGridDay: {
                  dayMaxEventRows: 4
                }
              }}
              initialView='dayGridMonth'
              buttonText={{
                today: 'Dzisiaj',
                month: 'Miesiąc',
                week: 'Tydzień',
                day: 'Dzień',
                list: 'Lista',
              }}
              selectable={true}
              select={this.handleDateSelect}
              eventClick={this.handleEventClick}
            />
          </Grid>
        </Grid>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  events: state.scheduler.events,
  eventTypes: state.scheduler.types,
});

export default connect(
  mapStateToProps,
  {
    getEventsOwner,
    getEventTypes,
    addEvent,
    clearEvents,
    clearEventTypes,
  }
)(MainEmployeeView);
