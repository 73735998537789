//database server url address definition

import { getCompany } from "./getCompany";

//local server:http://localhost:8000
//production server:https://radiolinebackend.extranet.nomizo.pl
//production server:https://radiolinebackendv2.extranet.nomizo.pl

//export const rurl = "http://localhost:8000"; //TEST - Local
//export const url = "http://192.168.88.101:8000"; //TEST - Proxmox
export const url = "https://radiolinebackendv2.extranet.nomizo.pl"; //PROD

export const api = "/api/v1"; // API version
export const spzoo = "/radioline_sp_zoo"; // Sp. z o.o. database
export const ab2008 = "/radioline_2008_ab"; // 2008 A.B. database
export const gmbh = "/radioline_gmbh"; // GmbH database
export const main = "/radioline_main"; // Main/Configuration database

export const serverurl = url + api;

export function getDatabaseUrl(id = getCompany()) {
  switch (id) {
    case 0:
      return main;
    case 1:
      return spzoo;
    case 2:
      return ab2008;
    case 3:
      return gmbh;
    default:
      return null;
  }
}