import React, { Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import timelinePlugin from '@fullcalendar/timeline'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getRoomEvents, clearRoomEvents, addRoomEvent, deleteRoomEvent } from "../../Redux/actions/schedulerRoom.js"

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: "50px"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0071BC"
      }
    },
    MuiButton: {
      root: {},
      textPrimary: {
        color: "#0071BC"
      },
      containedPrimary: {
        backgroundColor: "#1BAB7F",
        "&:hover": {
          backgroundColor: "#188E6A"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "#0071BC"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#0071bc"
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: "#0071BC"
      },
      daySelected: {
        backgroundColor: "#0071BC",
        "&:hover": {
          backgroundColor: "#0071BC"
        }
      },

      dayDisabled: {
        color: "gray"
      },
      current: {
        color: "#0071BC"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&.Mui-checked": {
          color: "#0071bc"
        }
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: "10px"
      }
    },
  }
});

class MainRoomView extends React.Component {
  state = {
    dialogOpen: false,
    detailsOpen: false,
    dateMsg: '',
    startDateString: '',
    description: '',
    author: '',
    authorId: null,
    startDate: null,
    endDate: null,
    allDay: false,
    eventPK: null,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    roomEvents: PropTypes.any.isRequired,
    getRoomEvents: PropTypes.func.isRequired,
    addRoomEvent: PropTypes.func.isRequired,
    clearRoomEvents: PropTypes.func.isRequired,
    deleteRoomEvent: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.getRoomEvents();
  }

  componentWillUnmount() {
    this.props.clearRoomEvents();
  }

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect()

    this.setState({
      dialogOpen: true,
      startDate: selectInfo.start,
      endDate: selectInfo.end,
      allDay: selectInfo.allDay,
    })
  }

  handleSaveEvent = () => {
    const newEvent = {
      title: "Zajęta",
      start: this.state.startDate,
      end: this.state.endDate,
      allDay: this.state.allDay,
      resourceId: 1,
      description: this.state.description,
      author: this.props.auth.user.last_name + ' ' + this.props.auth.user.first_name,
      authorId: this.props.auth.user.id,
    }

    this.props.addRoomEvent(newEvent);

    this.setState({
      dialogOpen: false,
      startDate: null,
      endDate: null,
      allDay: false,
    })
  }

  handleDetailsOpen = (eventObj) => {
    var startD = new Date(eventObj.start);
    var endD = new Date(eventObj.end);
    var roznica = endD - startD;
    var startDateString = startD.getDate() + '.' + (startD.getMonth() + 1) + '.' + startD.getFullYear();
    var dateMsg = "";

    if (roznica % 86400000 == 0) {
      var days = roznica / 86400000;
      if (days == 1) {
        dateMsg = '1 dzień';
      }
      else {
        dateMsg = days + ' dni';
      }
    }
    else {
      dateMsg = startD.toTimeString().split(' ')[0] + ' - ' + endD.toTimeString().split(' ')[0];
    }

    this.setState({
      detailsOpen: true,
      title: eventObj.title,
      description: eventObj.extendedProps.description,
      author: eventObj.extendedProps.author,
      authorId: eventObj.extendedProps.authorId,
      startDateString,
      dateMsg,
      eventPK: eventObj.extendedProps.pk,
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      title: '',
      startDate: null,
      endDate: null,
      allDay: false,
    })
  }

  handleDetailsClose = () => {
    this.setState({
      detailsOpen: false,
      title: '',
      description: '',
      author: '',
      authorId: null,
      startDateString: '',
      dateMsg: '',
      eventPK: null,
    })
  }

  handleEventClick = (clickInfo) => {
    var eventObj = clickInfo.event;
    clickInfo.jsEvent.preventDefault();
    this.handleDetailsOpen(eventObj);
  }

  handleDelete = () => {
    this.props.deleteRoomEvent(this.state.eventPK);
    this.handleDetailsClose();
  }

  handleTextList = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  render() {
    const {
      dialogOpen,
      detailsOpen,
      dateMsg,
      title,
      description,
      startDateString,
      authorId,
      author,
    } = this.state;
    const {
      id
    } = this.props.auth.user;

    const deleteBtn = (
      <Button onClick={this.handleDelete}>Usuń</Button>
    )

    return (
      <Fragment>
        <ThemeProvider theme={materialTheme}>
          <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
            <DialogTitle>Dodaj</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Dodaj nowe spotkanie
              </DialogContentText>
              <TextField
                margin="dense"
                id="description"
                label="Opis spotkania"
                type="text"
                style={{ width: "400px" }}
                variant="standard"
                value={description}
                onChange={this.handleTextList("description")}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose}>Anuluj</Button>
              <Button onClick={this.handleSaveEvent}>Gotowe</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <ThemeProvider theme={materialTheme}>
          <Dialog open={detailsOpen} onClose={this.handleDetailsClose}>
            <DialogTitle>Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Informacje dotyczące wydarzenia:
              </DialogContentText>
              <DialogContentText>
                Autor: {author}
              </DialogContentText>
              <DialogContentText>
                Opis: {description}
              </DialogContentText>
              <DialogContentText>
                Rozpoczęcie: {startDateString}
              </DialogContentText>
              <DialogContentText>
                Okres trwania: {dateMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {id == 121 || id == authorId ? deleteBtn : ''}
              <Button onClick={this.handleDetailsClose}>Zamknij</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>

        <Grid container justify="flex-start" style={{ width: "100%", padding: "1% 10%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyItems: "center" }}>
            <FullCalendar
              schedulerLicenseKey='0332943699-fcs-1696488100'
              plugins={[resourceTimeGridPlugin, timelinePlugin, interactionPlugin]}
              locale='plLocale'
              firstDay='1'
              headerToolbar={{
                right: 'prev,next today',
                center: 'title',
                left: 'resourceTimeGridWeek,resourceTimeGridDay'
              }}
              aspectRatio='2'
              events={this.props.roomEvents}
              resources={[{
                id: 1,
                title: 'Salka - 1 piętro - ul. Bułgarska 69/73'
              }]}
              views={{
                resourceTimeGridWeek: {
                  allDaySlot: true,
                },
                resourceTimeGridDay: {
                  allDaySlot: false,
                }
              }}
              buttonText={{
                today: 'Dzisiaj',
                month: 'Miesiąc',
                week: 'Tydzień',
                day: 'Dzień',
                list: 'Lista',
              }}
              initialView='resourceTimeGridWeek'
              selectable={true}
              select={this.handleDateSelect}
              eventClick={this.handleEventClick}
            />
          </Grid>
        </Grid>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  roomEvents: state.scheduler.roomEvents,
});

export default connect(
  mapStateToProps,
  {
    getRoomEvents,
    addRoomEvent,
    clearRoomEvents,
    deleteRoomEvent,
  }
)(MainRoomView);
