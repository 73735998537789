import { correctDateFormat } from "../Common/correctDateFormat";
import autoTable from "jspdf-autotable";

export function generate(doc, info) {
  doc.setProperties({
    title: 'Raport do inwentury',
    author: 'Radio Line',
    creator: 'Radio Line EXTRANET',
  });

  var signatureUser = correctDateFormat(info.signedUserDate) + " " + info.signedUser.first_name + " " + info.signedUser.last_name;
  var signatureAl = correctDateFormat(info.signedAlDate) + " " + info.signedAl.first_name + " " + info.signedAl.last_name;
  var signatureNa = correctDateFormat(info.signedNaDate) + " " + info.signedNa.first_name + " " + info.signedNa.last_name;
  var signatureEl = correctDateFormat(info.signedElDate) + " " + info.signedEl.first_name + " " + info.signedEl.last_name;
  var signatureIt = correctDateFormat(info.signedItDate) + " " + info.signedIt.first_name + " " + info.signedIt.last_name;
  var signaturePo = correctDateFormat(info.signedPoDate) + " " + info.signedPo.first_name + " " + info.signedPo.last_name;
  var signatureController = correctDateFormat(info.signedControllerDate) + " " + info.signedController.first_name + " " + info.signedController.last_name;

  var xOffset = doc.internal.pageSize.width / 2;
  var xStart = 15;
  var xEnd = 15;
  var yOffset = 25;
  doc.setLineWidth(0.25);

  //Always start with landscape page
  doc.deletePage(1);
  doc.addPage('a4', 'landscape');

  function justifyText(text, startX, startY, width) {
    var textArray = text.split(' ');
    var line = "";
    var lines = [];

    for (var word of textArray) {
      var tempLine = line + word + " ";
      if (doc.getTextWidth(tempLine) > width) {
        lines.push(line);
        line = word + " ";
      } else {
        line = tempLine;
      }
    }

    if (line.trim() != "") {
      lines.push(line);
    }

    for (var i = 0; i < lines.length; i++) {
      yOffset = startY + i * 5;
      doc.text(lines[i], startX, yOffset);
    }
  }

  function genLine(lineStart, lineHeight, lineLength) {
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(lineStart, lineHeight, lineStart + lineLength, lineHeight);
    doc.setLineDashPattern([], 0);
  }

  function genLineText2(text) {
    var lineEnd = doc.getTextWidth(text) + 10;
    doc.setLineDashPattern([0.25, 0.5], 0);
    doc.line(xStart, yOffset + 0.5, lineEnd, yOffset + 0.5);
    doc.setLineDashPattern([], 0);
  }

  function genSignature(yInternOffset, who, alignment, scale = 1) {
    var pageWidth = doc.internal.pageSize.width;
    var lineLength = 60;
    var yOffsetIncrement = 4;
    var xSignature;
    var signature = "";

    switch (alignment) {
      case 'left':
        xSignature = 10;
        break;
      case 'center':
        xSignature = (pageWidth / 2) - (lineLength / 2);
        break;
      case 'right':
        xSignature = pageWidth - lineLength - 10;
        break;
      default:
        xSignature = 10;
    }

    switch (who) {
      case 'Pracownik':
        signature = signatureUser;
        break;
      case 'Opiekun sprzętu BHP':
        signature = signatureAl;
      case 'Opiekun sprzętu narzędziowego':
        signature = signatureNa;
        break;
      case 'Opiekun sprzętu elektrycznego':
        signature = signatureEl;
        break;
      case 'Opiekun sprzętu IT':
        signature = signatureIt;
        break;
      case 'Opiekun pozostałego sprzętu':
        signature = signaturePo;
        break;
      case 'Kontroling':
        signature = signatureController;
        break;
      default:
        signature = "";
    }

    var scaledFontSize = 11 * scale;
    doc.setFontSize(scaledFontSize);
    var signatureWidth = doc.getTextWidth(signature) * scale;
    var signatureOffset = xSignature + (lineLength - signatureWidth) / 2;
    doc.text(signature, signatureOffset, yInternOffset);
    yInternOffset += yOffsetIncrement * scale;

    doc.setFontSize(8 * scale);
    signatureOffset = xSignature + (lineLength - doc.getTextWidth("Autoryzowano kodem") * scale) / 2;
    doc.text("Autoryzowano kodem", signatureOffset, yInternOffset);
    yInternOffset += 2 * scale;

    doc.setFontSize(11);
    genLine(xSignature, yInternOffset, lineLength);
    yInternOffset += 5 * scale;

    doc.setFontSize(scaledFontSize);
    signatureOffset = xSignature + (lineLength - doc.getTextWidth(`(${who})`) * scale) / 2;
    doc.text(`(${who})`, signatureOffset, yInternOffset);

    doc.setFontSize(11);
  }

  //Inwentura
  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Raport inwentury", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text(`IST/${new Date(info.inventoryStartDate).getFullYear()}/${String(info.id).padStart(4, '0')}/${String(info.inventoryUser.pk).padStart(3, '0')}`, xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFontSize(10);
  doc.setFont('DejaVuSansCondensed', 'normal');
  doc.text(`Data rozpoczęcia inwentaryzacji: ${correctDateFormat(info.inventoryStartDate)}`, xStart, yOffset);
  yOffset += 6;
  doc.text(`Data zakończenia inwentaryzacji: ${correctDateFormat(info.inventoryEndDate)}`, xStart, yOffset);
  yOffset += 6;
  doc.text(`Osoba podlegająca inwentaryzacji: ${info.inventoryUser.first_name} ${info.inventoryUser.last_name}`, xStart, yOffset);
  yOffset += 12;

  const groupOrder = ['al', 'na', 'el', 'it', 'po'];
  const groupNames = {
    'al': 'BHP',
    'na': 'Narzędzia',
    'el': 'Elektro-narzędzia',
    'it': 'IT',
    'po': 'Pozostałe'
  };
  const groupAcceptors = {
    'al': info.signedAl.first_name + " " + info.signedAl.last_name,
    'na': info.signedNa.first_name + " " + info.signedNa.last_name,
    'el': info.signedEl.first_name + " " + info.signedEl.last_name,
    'it': info.signedIt.first_name + " " + info.signedIt.last_name,
    'po': info.signedPo.first_name + " " + info.signedPo.last_name
  };

  const groupedAssets = info.assets.reduce((groups, item) => {
    const type = item.eqtype;
    const status = item.itemStatus;
    if (!groups[type]) {
      groups[type] = {};
    }
    if (!groups[type][status]) {
      groups[type][status] = [];
    }
    groups[type][status].push(item);
    return groups;
  }, {});

  const itemStatusDesc = {
    'dc': 'Do sprawdzenia',
    'do': 'Dodany',
    'po': 'Potwierdzony',
    'zl': 'Zezłomowany',
    'pr': 'Przesunięty',
  };

  groupOrder.forEach((type, groupIndex) => {
    const statusGroups = groupedAssets[type];
    if (statusGroups) {

      const requiredSpace = 50;
      if (doc.internal.pageSize.height - yOffset < requiredSpace) {
        doc.addPage('a4', 'landscape');
        yOffset = 15;
      }

      doc.setFont('DejaVuSansCondensed', 'bold');
      doc.text(`${groupNames[type]} | Akceptujący: ${groupAcceptors[type]}`, 10, yOffset);
      yOffset += 5;

      Object.keys(statusGroups).forEach((status) => {
        const group = statusGroups[status];
        if (group && group.length > 0) {

          const requiredSpace = 50;
          if (doc.internal.pageSize.height - yOffset < requiredSpace) {
            doc.addPage('a4', 'landscape');
            yOffset = 15;
          }

          doc.setFont('DejaVuSansCondensed', 'bold');
          doc.text(`Status: ${itemStatusDesc[status]}`, 20, yOffset);
          doc.setFont('DejaVuSansCondensed', 'normal');

          const options = {
            startY: yOffset + 5,
            head: [[
              'Lp.',
              'Przedmiot',
              'Posiadany',
              'Opis',
              'Komentarz pracownika',
              'Akceptacja',
              'Komentarz opiekuna',
              'Wartość',
              'Środek Trwały',
              'Komentarz kontrolingu'
            ]],
            body: group.map((item, idx) => [
              idx + 1,
              item.vendor + " | " + item.model + " | " + item.itemName,
              item.isOwned ? (item.equipment > 0 ? "Tak" : "Dodany") : "Nie",
              item.description,
              item.userComment == null || item.userComment == "" ? "-" : item.userComment,
              item.accepted ? "Tak" : "Nie",
              item.managerComment == null || item.managerComment == "" ? "-" : item.managerComment,
              item.value != null ? item.value : "-",
              item.isST != null ? (item.isST == true ? "Tak" : "Nie") : "-",
              item.controlComment == null || item.controlComment == "" ? "-" : item.controlComment
            ]),
            theme: 'plain',
            styles: {
              font: 'DejaVuSansCondensed',
              fontSize: 8,
              lineWidth: 0.5,
              valign: 'middle',
              halign: 'center',
              overflow: 'linebreak',
            },
            columnStyles: {
              0: { cellWidth: 10, halign: 'right' },
              1: { cellWidth: 40, halign: 'left' },
              2: { cellWidth: 20 },
              3: { cellWidth: 35, halign: 'left' },
              4: { cellWidth: 35, halign: 'left' },
              5: { cellWidth: 20 },
              6: { cellWidth: 35, halign: 'left' },
              7: { cellWidth: 20, halign: 'left' },
              8: { cellWidth: 20 },
              9: { cellWidth: 35, halign: 'left' }
            },
            pageBreak: 'auto',
            rowPageBreak: 'avoid',
          };

          autoTable(doc, options);
          yOffset = doc.lastAutoTable.finalY + 15;
        }
      });
    }
  });

  doc.addPage('a4', 'portrait');
  xOffset = doc.internal.pageSize.width / 2;

  yOffset = 25;
  //Oświadczenia
  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Oświadczenie pracownika do raportu inwentury", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text(`IST/${new Date(info.inventoryStartDate).getFullYear()}/${String(info.id).padStart(4, '0')}/${String(info.inventoryUser.pk).padStart(3, '0')}`, xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFontSize(10);
  doc.setFont('DejaVuSansCondensed', 'normal');
  justifyText("Oświadczam, iż wszystkie środki trwałe zostały spisane zgodnie z rzeczywistym stanem na dzień inwentaryzacji. Wszelkie różnice między stanem faktycznym, a stanem wynikającym z ewidencji zostały uwzględnione w raporcie.", xStart, yOffset, doc.internal.pageSize.width - xEnd - xStart);
  yOffset += 10;
  justifyText("Jednocześnie oświadczam, że ponoszę odpowiedzialność za wszelkie nieprawidłowości lub błędy w spisie, które mogą wynikać z mojego działania lub zaniedbania.", xStart, yOffset, doc.internal.pageSize.width - xEnd - xStart);
  yOffset += 10;
  doc.setFontSize(11);
  genSignature(yOffset, 'Pracownik', 'right');
  yOffset += 30;

  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Oświadczenie opiekunów biznesowych do raportu inwentury", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text(`IST/${new Date(info.inventoryStartDate).getFullYear()}/${String(info.id).padStart(4, '0')}/${String(info.inventoryUser.pk).padStart(3, '0')}`, xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFontSize(10);
  doc.setFont('DejaVuSansCondensed', 'normal');
  justifyText("Oświadczam, że wszystkie informacje zawarte w raporcie są zgodne z moją aktualną wiedzą.", xStart, yOffset, doc.internal.pageSize.width - xEnd - xStart);
  yOffset += 10;
  doc.setFontSize(11);
  genSignature(yOffset, 'Opiekun sprzętu BHP', 'left');
  yOffset += 10;
  genSignature(yOffset, 'Opiekun pozostałego sprzętu', 'center');
  yOffset -= 10;
  genSignature(yOffset, 'Opiekun sprzętu IT', 'right');
  yOffset += 20;
  genSignature(yOffset, 'Opiekun sprzętu elektrycznego', 'left');
  genSignature(yOffset, 'Opiekun sprzętu narzędziowego', 'right');
  yOffset += 30;

  doc.setFontSize(11);
  doc.setFont('DejaVuSansCondensed', 'bold');
  doc.text("Oświadczenie kontrolingu do raportu inwentury", xOffset, yOffset, 'center');
  yOffset += 6;
  doc.text(`IST/${new Date(info.inventoryStartDate).getFullYear()}/${String(info.id).padStart(4, '0')}/${String(info.inventoryUser.pk).padStart(3, '0')}`, xOffset, yOffset, 'center');
  yOffset += 15;

  doc.setFontSize(10);
  doc.setFont('DejaVuSansCondensed', 'normal');
  justifyText("Oświadczam, że wszystkie informacje zawarte w raporcie są zgodne z moją aktualną wiedzą.", xStart, yOffset, doc.internal.pageSize.width - xEnd - xStart);
  yOffset += 10;
  doc.setFontSize(11);
  genSignature(yOffset, 'Kontroling', 'right');
  //yOffset += 10;

  doc.save("raport_z_inwentury.pdf");
  //var pdfBase64 = doc.output('datauristring');

  const totalPages = doc.internal.getNumberOfPages();
  for (let i = totalPages; i > 1; i--) {
    doc.deletePage(i);
  }

  //return pdfBase64;
}