import React, { Fragment, Component } from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMyInventoryDetails,
  getEmployeeInventoryAssets,
  clearEquipmentModelsList,
} from "../../Redux/actions/assets";
import { clearUserDetails } from "../../Redux/actions/common";
import { withStyles } from "@material-ui/core/styles";
import AssetNavigation from "../Common/AssetNavigation";
import EmployeeInventoryList from "./EmployeeInventoryList";
import Typography from "@material-ui/core/Typography";
import { styles, materialTheme } from "../../common/radiolineStyles";
import { sendNewNotificationNoMail, sendNewNotificationControler } from "../../Redux/actions/employee";
import { createMuiTheme } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyInventoryDetails from "../EmployeeView/MyInventoryDetails.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from '@material-ui/core/DialogContentText';
import { updateEmpInventoryDetails, reportInventory } from "../../Redux/actions/assets.js";
import { sendCode, verifyCode, clearCode } from "../../Redux/actions/verifycodes"
import CommentIcon from '@material-ui/icons/Comment';

const materialThemeNext = createMuiTheme({
  palette: {
    primary: {
      main: "#0071BC",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "auto",
      },
    },
    MuiInputBase: {
      root: {
        width: "300px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#0071bc",
      },
    },
  },
});

export class InventoryManagerView extends Component {
  state = {
    checkedAssetPk: null,
    isAssetChecked: false,
    eqTypeSelected: "al",
    open: false,
    dialogOpen: false,
    chargeEmployeeFlag: false,
    alertOpen: false,
    activeListType: null,
    dialogNoInvAssetOpen: false,
    pesel: '',
    peselValid: null,
    peselError: false,
    code: '',
    openStep1: false,
    openStep2: false,
    assetDescription: '',
    comment: '',
    openCommentDialog: false,
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    queryInProgressCommon: PropTypes.bool.isRequired,
    queryInProgress: PropTypes.bool.isRequired,
    userDetails: PropTypes.object.isRequired,
    clearEquipmentModelsList: PropTypes.func.isRequired,
    getEmployeeInventoryAssets: PropTypes.func.isRequired,
    getMyInventoryDetails: PropTypes.func.isRequired,
    sendNewNotificationNoMail: PropTypes.func.isRequired,
    updateEmpInventoryDetails: PropTypes.func.isRequired,
    reportInventory: PropTypes.func.isRequired,
    codeValid: PropTypes.any.isRequired,
    sendCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    clearCode: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.clearUserDetails("s");

    const ownerId = this.props.invData.inventoryUser.pk;
    const permissions = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    if (permissions.al_keeper || isSuperUser) {
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
    }
    if (permissions.na_keeper || isSuperUser) {
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
    }
    if (permissions.el_keeper || isSuperUser) {
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
    }
    if (permissions.it_keeper || isSuperUser) {
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
    }
    if (permissions.po_keeper || isSuperUser) {
      this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userDetails.pk !== undefined &&
      nextProps.userDetails.pk !== this.props.userDetails.pk
    ) {
      const ownerId = this.props.invData.inventoryUser.pk;
      const permissions = this.props.auth.permissions;
      const isSuperUser = this.props.auth.user.is_superuser;

      if (permissions.al_keeper || isSuperUser) {
        this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "al");
      }
      if (permissions.na_keeper || isSuperUser) {
        this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "na");
      }
      if (permissions.el_keeper || isSuperUser) {
        this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "el");
      }
      if (permissions.it_keeper || isSuperUser) {
        this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "it");
      }
      if (permissions.po_keeper || isSuperUser) {
        this.props.getEmployeeInventoryAssets(this.props.inv, ownerId, "po");
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.codeValid) {
      this.handleAcceptInventory();
      this.props.clearCode();
      this.handleCloseStep2();
    }
    if (this.state.peselValid) {
      this.handleOpenStep2();
      this.handleCloseStep1();
    }
  }

  handleTextList = (name) => ({ target: { value } }) => {
    this.setState({
      //...this.state,
      [name]: value,
    });
  };

  handleChecked = (isChecked, assetpk, listtype) => {
    this.setState({
      isAssetChecked: isChecked,
      checkedAssetPk: assetpk,
      activeListType: listtype,
    });

    if (isChecked === true) {
      this.props.getMyInventoryDetails(assetpk);
    }
  };

  handleEqTypeSelected = (eqtypeselected) => {
    this.props.getEmployeeInventoryAssets(this.props.inv, this.props.invData.inventoryUser.pk, eqtypeselected);
    this.setState({
      eqTypeSelected: eqtypeselected,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  handleEmployeeSelected = (employeepk) => {
    this.setState({
      employeeSelected: employeepk,
    });
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
    });
  };

  handleClickCloseDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDownload = (eqtype) => {
    var assetsToBeDownloaded = [];

    if (eqtype === "al") {
      assetsToBeDownloaded = this.props.employeeAssetsAl;
    } else if (eqtype === "na") {
      assetsToBeDownloaded = this.props.employeeAssetsNa;
    } else if (eqtype === "el") {
      assetsToBeDownloaded = this.props.employeeAssetsEl;
    } else if (eqtype === "it") {
      assetsToBeDownloaded = this.props.employeeAssetsIt;
    } else if (eqtype === "po") {
      assetsToBeDownloaded = this.props.employeeAssetsPo;
    } else if (eqtype === "ws") {
      assetsToBeDownloaded = this.props.employeeAssetsAl.concat(
        this.props.employeeAssetsNa,
        this.props.employeeAssetsEl,
        this.props.employeeAssetsIt,
        this.props.employeeAssetsPo
      );
    }

    if (assetsToBeDownloaded.length !== 0) {
      var csvRow = [];
      var A = [
        [
          "Grupa",
          "Nazwa",
          "Model",
          "Producent",
          "Numer seryjny",
          "Numer Produktu",
          "Data zakupu",
          "Data przekazania",
          "Opis dodatkowy",
          "Właściciel środka trwałego",
        ],
      ];
      var re = assetsToBeDownloaded;

      for (var item = 0; item < re.length; item++) {
        A.push([
          re[item].model.eqtype_description,
          re[item].model.name,
          re[item].model.description,
          re[item].model.vendor,
          re[item].serialNumber,
          re[item].partNumber,
          re[item].purchaseDate,
          re[item].assignDate,
          re[item].description,
          re[item].owner.first_name + " " + re[item].owner.last_name,
        ]);
      }

      for (var i = 0; i < A.length; ++i) {
        csvRow.push(A[i].join(";"));
      }

      var csvString = csvRow.join("\n");
      //var a = document.createElement("a");
      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString)
      );
      if (eqtype === "ws") {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name}.csv`
        );
      } else {
        link.setAttribute(
          "download",
          `SrodkiTrwale${re[0].owner.first_name + re[0].owner.last_name + "_"}${re[0].model.eqtype_description
          }.csv`
        );
      }
      link.click();
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  handleClearEmployee = () => {
    this.setState({
      //eqTypeSelected: null,
      checkedAssetPk: null,
      isAssetChecked: false,
    });
  };

  setFilter = (filtername) => {
    this.setState({
      activeFilter: filtername,
    });

    if (
      this.state.isAssetChecked &&
      this.state.activeListType !== "AssetListManager"
    ) {
      this.setState({
        checkedAssetPk: null,
        isAssetChecked: false,
        activeListType: null,
      });
    }
  };

  handleClickPreview = (event) => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClickInvYes = (event) => {
    var body = {
      accepted: true,
    }

    if (this.props.myInventoryDetails.isOwned == true && this.props.myInventoryDetails.equipment != null) {
      body.itemStatus = "po";
    }

    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
  };

  handleClickInvNo = (event) => {
    this.setState({
      dialogNoInvAssetOpen: true,
    })
  };

  handleNoInvAsset = (event, stage) => {
    var body = {};
    var stage = null;
    const status = this.props.invData.status;
    const isSuperUser = this.props.auth.user.is_superuser;
    const permissions = this.props.auth.permissions;
    if ((permissions.al_keeper || permissions.na_keeper || permissions.el_keeper || permissions.it_keeper || permissions.po_keeper || isSuperUser) && status == "re") {
      stage = "manager";
    }
    else if ((this.props.auth.permissions.controlling || isSuperUser) && status == "ma") {
      stage = "admin";
    }

    if (stage == "manager") {
      body = {
        managerComment: this.state.assetDescription,
        accepted: false,
      }

      this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
    }

    this.setState({
      dialogNoInvAssetOpen: false,
    });
  };

  handleClickCloseNoInvDialog = () => {
    this.setState({
      dialogNoInvAssetOpen: false,
    });
  };

  handleAcceptInventory = (event) => {
    var stage = null;
    const status = this.props.invData.status;
    const isSuperUser = this.props.auth.user.is_superuser;
    const permissions = this.props.auth.permissions;
    const invData = this.props.invData;
    var signCount = 0;

    if ((permissions.al_keeper || permissions.na_keeper || permissions.el_keeper || permissions.it_keeper || permissions.po_keeper || isSuperUser) && status == "re") {
      stage = "manager";
    }
    else if ((this.props.auth.permissions.controlling || isSuperUser) && status == "ma") {
      stage = "admin";
    }

    if (invData.signedAl != null && invData.signedAl != '') {
      signCount++;
    }
    if (invData.signedNa != null && invData.signedNa != '') {
      signCount++;
    }
    if (invData.signedEl != null && invData.signedEl != '') {
      signCount++;
    }
    if (invData.signedIt != null && invData.signedIt != '') {
      signCount++;
    }
    if (invData.signedPo != null && invData.signedPo != '') {
      signCount++;
    }

    if (stage === "manager" && signCount < 4) {
      var data = {
        "inventoryEndDate": new Date().toISOString(),
      }
      if (permissions.al_keeper || isSuperUser) {
        data.signedAl = this.props.auth.user.id;
        data.signedAlDate = new Date().toISOString();
      }
      if (permissions.na_keeper || isSuperUser) {
        data.signedNa = this.props.auth.user.id;
        data.signedNaDate = new Date().toISOString();
      }
      if (permissions.el_keeper || isSuperUser) {
        data.signedEl = this.props.auth.user.id;
        data.signedElDate = new Date().toISOString();
      }
      if (permissions.it_keeper || isSuperUser) {
        data.signedIt = this.props.auth.user.id;
        data.signedItDate = new Date().toISOString();
      }
      if (permissions.po_keeper || isSuperUser) {
        data.signedPo = this.props.auth.user.id;
        data.signedPoDate = new Date().toISOString();
      }

      this.props.reportInventory(this.props.inv, data);
      this.props.closeViewDialog();
    }
    else if (stage === "manager" && signCount >= 4) {
      const invNumber = `IST/${new Date(invData.inventoryStartDate).getFullYear()}/${String(invData.pk).padStart(4, '0')}/${String(invData.inventoryUser.pk).padStart(3, '0')}`;

      var data = {
        "status": "ma",
        "inventoryEndDate": new Date().toISOString(),
      }
      if (permissions.al_keeper || isSuperUser) {
        data.signedAl = this.props.auth.user.id;
        data.signedAlDate = new Date().toISOString();
      }
      if (permissions.na_keeper || isSuperUser) {
        data.signedNa = this.props.auth.user.id;
        data.signedNaDate = new Date().toISOString();
      }
      if (permissions.el_keeper || isSuperUser) {
        data.signedEl = this.props.auth.user.id;
        data.signedElDate = new Date().toISOString();
      }
      if (permissions.it_keeper || isSuperUser) {
        data.signedIt = this.props.auth.user.id;
        data.signedItDate = new Date().toISOString();
      }
      if (permissions.po_keeper || isSuperUser) {
        data.signedPo = this.props.auth.user.id;
        data.signedPoDate = new Date().toISOString();
      }

      const notifyBody = {
        module: "Środki Trwałe",
        topic: "Inwnentura pracownika została zaakceptowana przez opiekunów biznesowych",
        message:
          "Opiekuni biznesowi zaakceptowali inwenturę nr. " + invNumber + "\n" +
          "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET."
      }

      const notifyBodyEMP = {
        recipient: this.props.invData.inventoryUser.pk,
        module: "Środki Trwałe",
        topic: "Twoja inwnentura została zaakceptowana opiekunów biznesowych",
        message:
          "Opiekuni biznesowi zaakceptowali twoją inwenturę nr. " + invNumber + "\n" +
          "Więcej informacji znajdziesz w module Środki Trwałe w EXTRANET."
      }

      this.props.reportInventory(this.props.inv, data);
      this.props.sendNewNotificationControler(notifyBody);
      this.props.sendNewNotificationNoMail(notifyBodyEMP);
      this.props.closeViewDialog();
    }
  }

  handleOpenStep1 = (eventObj) => {
    this.setState({
      openStep1: true,
    })
  }

  handleCloseStep1 = () => {
    this.setState({
      openStep1: false,
      pesel: '',
      peselValid: null,
      peselError: false,
    })
  }

  handleOpenStep2 = (eventObj) => {
    this.handleNewCode();
    this.setState({
      openStep2: true,
    })
  }

  handleCloseStep2 = () => {
    this.setState({
      openStep2: false,
      code: '',
    })
  }

  handleNewCode = () => {
    const body = {
      user: this.props.auth.user.id,
      recipient: this.props.auth.user.email,
    }

    this.props.sendCode(body);
  }

  handleCheckPesel = () => {
    if (this.state.pesel == this.props.auth.main_profile.pesel) {
      this.setState({
        peselValid: true,
        peselError: false,
      })
    }
    else {
      this.setState({
        peselValid: false,
        peselError: true,
      })
    }
  }

  handleCheckCode = () => {
    const body = {
      user: this.props.auth.user.id,
      code: this.state.code,
    }
    this.props.verifyCode(body);
  }

  handleOpenCommentDialog = () => {
    this.setState({
      openCommentDialog: true,
      comment: this.props.myInventoryDetails.managerComment,
    });
  }

  handleCloseCommentDialog = () => {
    this.setState({
      openCommentDialog: false,
    });
  }

  handleCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  }

  handleSubmitComment = () => {
    const body = {
      "managerComment": this.state.comment,
    }
    this.props.updateEmpInventoryDetails(this.state.checkedAssetPk, body);
    this.setState({
      openCommentDialog: false,
    });
  }

  render() {
    const {
      eqTypeSelected,
      dialogOpen,
      activeListType,
      isAssetChecked,
      dialogNoInvAssetOpen,
      openStep1,
      openStep2,
      pesel,
      peselError,
      code,
      comment,
      openCommentDialog,
    } = this.state;
    const {
      classes,
      employeeInventoryAl,
      employeeInventoryNa,
      employeeInventoryEl,
      employeeInventoryIt,
      employeeInventoryPo,
      queryInProgress,
    } = this.props;
    const permissions = this.props.auth.permissions;
    const isSuperUser = this.props.auth.user.is_superuser;

    var employeeInventoryAssets = [];
    var eqTypePerms = false;

    if (eqTypeSelected === "al" && (permissions.al_keeper || isSuperUser)) {
      employeeInventoryAssets = employeeInventoryAl;
      eqTypePerms = true;
    } else if (eqTypeSelected === "na" && (permissions.na_keeper || isSuperUser)) {
      employeeInventoryAssets = employeeInventoryNa;
      eqTypePerms = true;
    } else if (eqTypeSelected === "el" && (permissions.el_keeper || isSuperUser)) {
      employeeInventoryAssets = employeeInventoryEl;
      eqTypePerms = true;
    } else if (eqTypeSelected === "it" && (permissions.it_keeper || isSuperUser)) {
      employeeInventoryAssets = employeeInventoryIt;
      eqTypePerms = true;
    } else if (eqTypeSelected === "po" && (permissions.po_keeper || isSuperUser)) {
      employeeInventoryAssets = employeeInventoryPo;
      eqTypePerms = true;
    }

    var inventoryDetails = null;

    const circle = (
      <Grid
        container
        className={classes.root}
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Grid>
    );

    if (
      (dialogOpen === true) &&
      this.props.myInventoryDetails.pk !== undefined
    ) {
      inventoryDetails = (
        <MyInventoryDetails myInventoryDetails={this.props.myInventoryDetails}></MyInventoryDetails>
      );
    } else {
      inventoryDetails = <Fragment></Fragment>;
    }

    const previewButtonDi = (
      <Fab
        disabled
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const previewButtonEn = (
      <Fab
        color="inherit"
        className={classes.fabblue}
        size="small"
        aria-label="scroll back to top"
      >
        <VisibilityIcon />
      </Fab>
    );

    const diaolgPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Autoryzacja wniosku: Krok 1 - PESEL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Podaj swój numer PESEL
          </DialogContentText>
          <TextField
            margin="dense"
            id="pesel"
            label="Wprowadź PESEL"
            type="text"
            fullWidth
            variant="standard"
            value={pesel}
            onChange={this.handleTextList("pesel")}
            onKeyPress={this.handleKeyPress}
            error={peselError}
            helperText={peselError ? "Nieprawidłowy numer PESEL" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Anuluj</Button>
          <Button onClick={this.handleCheckPesel}>Dalej</Button>
        </DialogActions>
      </Dialog>
    )

    const contact_string = 'mailto:it@radioline.com.pl?subject=Uzupełnienie danych - ' + this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name;

    const diaolgNoPesel = (
      <Dialog open={openStep1} onClose={this.handleCloseStep1}>
        <DialogTitle>Błąd - brak wymaganych danych w systemie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            W celu uzupełnienia danych skontaktuj się z działem IT.
          </DialogContentText>
          <DialogContentText>
            Kontakt: <a href={contact_string}>it@radioline.com.pl</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseStep1}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    )

    var calcInventory = 0;
    var calcDecided = 0;
    var calcAdded = 0;
    if (permissions.al_keeper || isSuperUser) {
      calcInventory += employeeInventoryAl.length;
      calcDecided += employeeInventoryAl.filter(asset => asset.accepted != null).length;
      calcAdded += employeeInventoryAl.filter(asset => asset.equipment == null).length;
    }
    if (permissions.na_keeper || isSuperUser) {
      calcInventory += employeeInventoryNa.length;
      calcDecided += employeeInventoryNa.filter(asset => asset.accepted != null).length;
      calcAdded += employeeInventoryNa.filter(asset => asset.equipment == null).length;
    }
    if (permissions.el_keeper || isSuperUser) {
      calcInventory += employeeInventoryEl.length;
      calcDecided += employeeInventoryEl.filter(asset => asset.accepted != null).length;
      calcAdded += employeeInventoryEl.filter(asset => asset.equipment == null).length;
    }
    if (permissions.it_keeper || isSuperUser) {
      calcInventory += employeeInventoryIt.length;
      calcDecided += employeeInventoryIt.filter(asset => asset.accepted != null).length;
      calcAdded += employeeInventoryIt.filter(asset => asset.equipment == null).length;
    }
    if (permissions.po_keeper || isSuperUser) {
      calcInventory += employeeInventoryPo.length;
      calcDecided += employeeInventoryPo.filter(asset => asset.accepted != null).length;
      calcAdded += employeeInventoryPo.filter(asset => asset.equipment == null).length;
    }

    console.log(calcInventory);
    console.log(calcDecided);
    console.log(calcDecided / calcInventory);

    const activeInventoryInfo = (
      <ThemeProvider theme={materialTheme}>
        <Grid container justify="center" style={{ textAlign: "center" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography>
              Liczba środków trwałych w tej kategorii: {employeeInventoryAssets.length}
            </Typography>
            <Typography>
              Środki trwałe pozostałe weryfikacji w tej kategorii: {employeeInventoryAssets.filter(asset => asset.accepted == null).length}
            </Typography>
            <br></br>
            <Typography>
              Zweryfikowane ogółem: {(calcInventory != 0 ? (calcDecided / calcInventory * 100) : 0).toFixed(2)}%
            </Typography>
            <Typography>
              Dodane środki trwałe: {calcAdded}
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    )

    const acceptBtnEn = (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={this.handleOpenStep1}
        style={{ margin: "8px", padding: "8px", backgroundColor: "#4caf50" }}
      >Zatwierdź</Button>
    );

    const acceptBtnDis = (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled
        style={{ margin: "8px", padding: "8px" }}
      >Zatwierdź</Button>
    );

    const acceptBtnDis2 = (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled
        style={{ margin: "8px", padding: "8px" }}
      >Inwentura została już zatwierdzona</Button>
    );

    const notFinishedInventory = (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Card style={{ overflow: "auto", marginTop: 0, marginBottom: "16px" }}>
              <CardBody>
                <Typography
                  style={{ marginBottom: "20px", textAlign: "center" }}
                  className={classes.textType}
                >
                  Zatwierdź inwenturę pracownika
                </Typography>
                {activeInventoryInfo}
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={1} sm={3} md={3}></Grid>
                  <Grid item xs={10} sm={6} md={6}>
                    {this.props.invData.status == "re" || this.props.invData.status == "ip" ? (calcDecided == calcInventory ? acceptBtnEn : acceptBtnDis) : acceptBtnDis2}
                  </Grid>
                  <Grid item xs={1} sm={3} md={3}></Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );

    return (
      <Fragment>
        <div className={classes.section}>
          <Box m={2}>
            {this.props.invData.status != "fi" ? notFinishedInventory : <Fragment></Fragment>}
            <AssetNavigation
              eqTypeSelected={this.handleEqTypeSelected}
            />
            <div style={{ marginTop: "30px" }}></div>
            <EmployeeInventoryList
              assets={employeeInventoryAssets}
              checked={this.handleChecked}
              downloadTrigger={this.handleDownload}
              eqTypeSelected={eqTypeSelected}
              activeListType={activeListType}
              status={this.props.invData.status}
              eqTypePerms={eqTypePerms}
              owner={this.props.invData.inventoryUser}
            />
          </Box>

          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleClickPreview}
              role="presentation"
              className={classes.fb2}
            >
              <Tooltip title="Pokaż szczegóły" placement="left">
                {queryInProgress ? previewButtonDi : previewButtonEn}
              </Tooltip>
            </div>
          </Zoom>
          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleClickInvNo}
              role="presentation"
              className={classes.fb1}
            >
              <Tooltip title="Nie akceptuję" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabred}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleClickInvYes}
              role="presentation"
              className={classes.fb3}
            >
              <Tooltip title="Akceptuję" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabgreen}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <CheckIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>

          <Zoom in={isAssetChecked}>
            <div
              onClick={this.handleOpenCommentDialog}
              role="presentation"
              className={classes.fb5}
            >
              <Tooltip title="Dodaj komentarz" placement="left">
                <Fab
                  color="inherit"
                  className={classes.fabblue}
                  size="small"
                  aria-label="scroll back to top"
                >
                  <CommentIcon />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
          <Dialog open={openCommentDialog} onClose={this.handleCloseCommentDialog} fullWidth maxWidth="md">
            <DialogTitle>Dodaj komentarz</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                label="Komentarz"
                type="text"
                fullWidth
                value={comment}
                onChange={this.handleCommentChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseCommentDialog} color="primary">
                Anuluj
              </Button>
              <Button onClick={this.handleSubmitComment} color="primary">
                Dodaj
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullScreen
            open={dialogOpen}
            onClose={this.handleClickCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <AppBar position="static" style={{ background: "#0071BC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClickCloseDialog}
                  aria-label="close"
                  className={classes.menuButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Szczegóły środka trwałego
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ background: "#f5f5f5" }}>
              {queryInProgress ? circle : inventoryDetails}
            </DialogContent>
          </Dialog>

          <Dialog
            open={dialogNoInvAssetOpen}
            onClose={this.handleClickCloseNoInvDialog}
            aria-labelledby="form-dialog-title"
            fullWidth maxWidth="md"
          >
            <DialogTitle>Podaj powód odmowy akceptacji</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                name="assetDescription"
                fullWidth
                value={this.state.assetDescription}
                onChange={this.handleTextChange}
                placeholder="np. zbyt szybko uszkodzony"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClickCloseNoInvDialog} color="primary">
                Anuluj
              </Button>
              <Button onClick={this.handleNoInvAsset} color="primary" disabled={this.state.assetDescription === ""}>
                Prześlij
              </Button>
            </DialogActions>
          </Dialog>

          {this.props.auth.main_profile.pesel != null ? diaolgPesel : diaolgNoPesel}

          <Dialog open={openStep2} onClose={this.handleCloseStep2}>
            <DialogTitle>Autoryzacja wniosku: Krok 2 - Kod E-Mail</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Na twój adres e-mail został wysłany 6-cyfrowy kod autoryzacyjny.
              </DialogContentText>
              <TextField
                margin="dense"
                id="code"
                label="Wprowadź kod"
                type="text"
                fullWidth
                variant="standard"
                value={code}
                onChange={this.handleTextList("code")}
                onKeyPress={this.handleKeyPress}
              />
              <DialogContentText></DialogContentText>
              <DialogContentText>
                <Button onClick={this.handleNewCode}>Wyślij nowy kod</Button>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseStep2}>Anuluj</Button>
              <Button onClick={this.handleCheckCode}>Podpisz</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInventoryAl: state.assets.employeeInventoryAl,
  employeeInventoryNa: state.assets.employeeInventoryNa,
  employeeInventoryEl: state.assets.employeeInventoryEl,
  employeeInventoryIt: state.assets.employeeInventoryIt,
  employeeInventoryPo: state.assets.employeeInventoryPo,
  queryInProgress: state.assets.queryInProgress,
  auth: state.auth,
  usersList: state.common.users,
  userDetails: state.common.suser,
  queryInProgressCommon: state.common.queryInProgress,
  myInventoryDetails: state.assets.myInventoryDetails,
  codeValid: state.verifycodes.valid,
});

export default connect(
  mapStateToProps,
  {
    getEmployeeInventoryAssets,
    clearUserDetails,
    clearEquipmentModelsList,
    getMyInventoryDetails,
    sendNewNotificationNoMail,
    sendNewNotificationControler,
    updateEmpInventoryDetails,
    reportInventory,
    sendCode,
    verifyCode,
    clearCode,
  }
)(withStyles(styles, { withTheme: true })(InventoryManagerView));
